import { Container, Grid, Skeleton } from '@common/components'

const PricingPlaceholder = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '50px',
      }}
    >
      <Skeleton height={45} width={845} />
      <Skeleton height={45} width={700} sx={{ marginTop: '20px' }} />
      <Skeleton height={45} width={300} sx={{ marginTop: '20px' }} />
      <Skeleton height={60} width={300} sx={{ marginTop: '20px' }} />
      <Grid container spacing={1} sx={{ marginTop: '50px' }}>
        <Grid item sm={6} md={3}>
          <Skeleton height={250} width={220} />
        </Grid>
        <Grid item sm={6} md={3}>
          <Skeleton height={250} width={220} />
        </Grid>
        <Grid item sm={6} md={3}>
          <Skeleton height={250} width={220} />
        </Grid>
        <Grid item sm={6} md={3}>
          <Skeleton height={250} width={220} />
        </Grid>
      </Grid>
      <Skeleton width={100} height={45} />
    </Container>
  )
}
export default PricingPlaceholder
