import { useUser } from '@features/auth/hooks'
import { ReactNode, Suspense } from 'react'
import { Navigate, useLocation, useNavigate, useNavigationType } from '@lib/router'
import { Helmet } from '@lib/helmet'
import {
  COMPANY_ACCOUNT_ROUTE,
  DASHBOARD_ROUTE,
  EVENT_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MY_CLASSROOMS_ROUTE,
  NOTES_ROUTE,
  PRICING_ROUTE,
  SIMPLE_LOGIN_ROUTE,
} from '@common/constants/routes'
import { useAppSelector } from '@common/hooks'
import { selectUser } from '@features/auth/auth-slice'
import NotFound from '@pages/not-found'
import { isUNSW } from '@utils'

interface PageLayoutProps {
  children: ReactNode
  title: string
  description?: string
  fallback: ReactNode
  protected?: boolean
  public?: boolean
  redirectMessage?: string
  roles?: string[]
  simple?: boolean
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    title,
    fallback,
    protected: protectedRoute,
    public: publicRoute,
    simple = false,
    redirectMessage,
    children,
    roles,
  } = props
  const user = useAppSelector(selectUser)
  const { isTeacher, isStudent, isCompany } = useUser()
  const location = useLocation()
  const navigationType = useNavigationType()
  const navigate = useNavigate()
  const { state } = location

  const previousPathname = state?.from || '/'
  if (
    location.pathname === SIMPLE_LOGIN_ROUTE &&
    previousPathname?.pathname?.startsWith(EVENT_ROUTE)
  ) {
    navigate('/')
  }

  if (protectedRoute && !user) {
    if (simple) {
      return (
        <Navigate
          to={SIMPLE_LOGIN_ROUTE}
          state={{
            message: redirectMessage ?? 'You have to login first',
            from: location,
          }}
        />
      )
    }
    return (
      <Navigate
        to={LOGIN_ROUTE}
        state={{
          message: redirectMessage ?? 'You have to login first',
          from: location,
        }}
        // replace
      />
    )
  }
  if (publicRoute && user && user?.verified) {
    if (location.pathname === '/verify' && isTeacher) {
      return (
        <Navigate
          to={PRICING_ROUTE}
          state={{
            message: redirectMessage ?? 'You have to login first',
            from: location,
          }}
          // replace
        />
      )
    }
    if (location.pathname === '/verify' && isCompany) {
      return (
        <Navigate
          to={COMPANY_ACCOUNT_ROUTE}
          state={{
            message: redirectMessage ?? 'You have to login first',
            from: location,
          }}
          // replace
        />
      )
    }
    return (
      <Navigate
        to={
          navigationType === 'POP'
            ? isUNSW()
              ? user?.status === 'STUDENT'
                ? DASHBOARD_ROUTE
                : NOTES_ROUTE
              : HOME_ROUTE
            : -1
        }
        state={{
          message: redirectMessage ?? 'You have to login first',
          from: location,
        }}
        // replace
      />
    )
  }

  // TODO : refactor this into IF not hasAllowedRoles return not found page  ( simple :) )
  if (roles && user) {
    if (roles.includes('TEACHER') && isTeacher) {
      return (
        <Suspense fallback={fallback}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {children}
        </Suspense>
      )
    }
    if (roles.includes('STUDENT') && isStudent) {
      return (
        <Suspense fallback={fallback}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {children}
        </Suspense>
      )
    } else {
      return <NotFound sx={{ mt: -15 }} />
    }
  }

  return (
    <Suspense fallback={fallback}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Suspense>
  )
}

export default PageLayout
