import { lazy, Suspense } from 'react'
import { Box } from '@common/components'
import { Outlet } from '@lib/router'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@common/utils/styles'
import { useTheme } from '@features/theme/theme.hooks'
import Footer from '../footer'
import darkTheme from '@styles/themes/dark-theme'
import lightTheme from '@styles/themes/light-theme'
import lightThemeUnsw from '@styles/themes/unsw-light-theme'
import HeaderPlaceholder from '../header/placeholder'
import ModulesChecker from '@features/modules-checker'
import { isUNSW } from '@utils'
import WelcomeModal from '@features/auth/components/welcome-modal'

const Header = lazy(() => import('../header'))

const AppLayout: React.FC = () => {
  const { isDark } = useTheme()

  return (
    <ThemeProvider theme={isUNSW() ? lightThemeUnsw : isDark ? darkTheme : lightTheme}>
      <GlobalStyles
        styles={{
          body: {
            overflowY: 'scroll',
          },
        }}
      />
      <CssBaseline />
      <Suspense fallback={<HeaderPlaceholder />}>
        <Header />
      </Suspense>
      <Box minHeight='calc( 100vh - 66px )'>
        <Outlet />
      </Box>
      <Footer />
      <ModulesChecker />
      {!isUNSW() ? <WelcomeModal /> : null}
    </ThemeProvider>
  )
}

export default AppLayout
