import { Box } from '@common/components'
import { styled } from '@common/utils/styles'

export const BoxDay = styled(Box)(({ theme }) => ({
  height: '46px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'rgba(230,230,230,0.6)',
  padding: '4px 15px',
  borderRadius: '5px 5px 0 0',
  borderBottom: `1.5px solid ${theme.palette.primary.main}`,
}))
