import { Container, Grid } from '@common/components'
import { GlobalStyles } from '@mui/material'

interface UsernameContainerProps {
  children: React.ReactNode
}
const UsernameContainer: React.FC<UsernameContainerProps> = (props) => {
  const { children, ...rest } = props
  return (
    <>
      <GlobalStyles
        styles={(theme: any) => {
          return {
            body: {
              backgroundColor: theme.palette.mode === 'light' ?? '#D8EEFF',
            },
          }
        }}
      />
      <Container maxWidth='lg' {...rest}>
        {children}
      </Container>
    </>
  )
}

export default UsernameContainer
