import { Skeleton, Grid, Stack, Paper, Box } from '@common/components'

const UserNamePlaceholder = () => {
  return (
    <Grid container>
      <Grid xs={12} md={8} item>
        <Paper
          sx={{
            borderRadius: '12px',
            height: '100%',
            border: '1px solid',
            p: 3,
          }}
        >
          <Stack
            direction='row'
            spacing={2}
            sx={{ padding: '25px', justifyContent: 'space-between' }}
          >
            <Stack direction='row' alignItems={'center'} spacing={2}>
              <Skeleton animation='wave' variant='circular' width={40} height={40} />

              <Skeleton variant='text' width={300} height={40} sx={{ fontSize: '1rem' }} />
            </Stack>

            <Skeleton variant='rectangular' sx={{ borderRadius: '10px' }} width={150} height={40} />
          </Stack>
          <Stack direction={'column'}>
            <Skeleton variant='text' width={400} height={35} sx={{ fontSize: '1rem' }} />
            <Skeleton variant='text' width={400} height={35} sx={{ fontSize: '1rem' }} />

            <Skeleton variant='rectangular' sx={{ maxWidth: 500 }} height={250} />
          </Stack>
          <Stack direction={'row'} spacing={3} sx={{ mt: '20px' }}></Stack>

          <Skeleton variant='text' width={150} height={15} />
          <Stack direction={'row'} spacing={3}>
            <Skeleton
              variant='rectangular'
              sx={{ borderRadius: '10px', maxWidth: '150' }}
              width={150}
              height={40}
            />
            <Skeleton
              variant='rectangular'
              sx={{ borderRadius: '10px', maxWidth: '150' }}
              width={150}
              height={40}
            />
            <Skeleton
              variant='rectangular'
              sx={{ borderRadius: '10px', maxWidth: '150' }}
              width={150}
              height={40}
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default UserNamePlaceholder
