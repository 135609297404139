import { Stack } from '@common/components'
import { Container, Skeleton } from '@mui/material'

const WeblinksPlaceholder = () => {
  return (
    <Container>
      <Stack
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        spacing={2}
        sx={{ marginBottom: '20px' }}
      >
        <Skeleton height={56} width={500} variant='rounded' />
        <Skeleton variant='rounded' height={45} width={112} />
      </Stack>
      <Skeleton height={80} variant='rounded'></Skeleton>
      <Skeleton height={80} variant='rounded' sx={{ mt: 2 }}></Skeleton>
      <Skeleton height={80} variant='rounded' sx={{ mt: 2 }}></Skeleton>
      <Skeleton height={80} variant='rounded' sx={{ mt: 2 }}></Skeleton>
    </Container>
  )
}

export default WeblinksPlaceholder
