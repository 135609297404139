import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from './ProgressBar'
import { Steps } from './Steps'
import SwipeableViews from 'react-swipeable-views'
import { Container } from './ProgressView.styles'

const ProgressView = (props) => {
  const { children, showSteps, currentStep, showProgressBar, swipeable, ...restProps } = props
  const validateCurrentStep = (step) => Math.min(step, children.length - 1)
  return (
    <Container {...restProps}>
      {showSteps && (
        <Steps currentStep={validateCurrentStep(currentStep)} numberOfSteps={children.length} />
      )}
      <SwipeableViews
        animateTransitions={swipeable}
        index={validateCurrentStep(currentStep)}
        style={{ height: '100%' }}
        containerStyle={{
          height: '100%',
          willChange: null,
          transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
        }}
      >
        {children}
      </SwipeableViews>
      {showProgressBar && (
        <ProgressBar
          currentStep={validateCurrentStep(currentStep)}
          numberOfSteps={children.length}
          sx={{ position: 'absolute', bottom: 0, left: 0 }}
        />
      )}
    </Container>
  )
}

ProgressView.propTypes = {
  currentStep: PropTypes.number,
  showSteps: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  swipeable: PropTypes.bool,
  children: PropTypes.any,
}

ProgressView.defaultProps = {
  showProgressBar: true,
  showSteps: true,
  swipeable: true,
}

export default ProgressView
