import { Box, Typography } from '@common/components'

const RecordingDot = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <div className='blinking-dot'>
        <style>
          {`
          .blinking-dot {
            width: 15px;
            height: 15px;
            background: rgba(179, 0, 0, 0.8);
            border-radius: 15px;
            animation: blink 1s infinite;
          }

          @keyframes blink {
            50% {
              opacity: 0;
            }
          }
        `}
        </style>
      </div>
      <Typography sx={{ marginLeft: '8px' }}>Speech AI</Typography>
    </Box>
  )
}

export default RecordingDot
