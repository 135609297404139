import React from 'react'
import { Tab, Tabs, Typography } from '@common/components'
import {
  DashboardCustomizeIcon,
  FactCheckIcon,
  FeedIcon,
  LyricsIcon,
  RadioButtonCheckedIcon,
} from '@common/icons'
import { RouterLink, useParams } from '@lib/router'
import {
  FEEDBACK_ROUTE,
  NOTES_ROUTE,
  QUIZ_VOCAB_ROUTE,
  RECORDINGS_ROUTE,
  SUMMARY_ROUTE,
  TESTMYSELF_ROUTE,
} from '@common/constants/routes'
import { useAppDispatch, useAppSelector, useToggle } from '@common/hooks'
import { useTheme } from '@features/theme/theme.hooks'
import KickedFromEventModal from '@features/inventory/components/kicked-from-event-modal'
import { setKickedFromEvent } from '@features/inventory/inventory.slice'
import { useUser } from '@features/auth/hooks'
import { isUNSW } from '@utils'
import VocabIcon from '../../icons/vocab-icon'

const InventoryTabs = (props) => {
  const { value, onChange, navLinks } = props
  const { feedback_id } = useParams()
  const { isDark } = useTheme()

  const { kickedFromEvent } = useAppSelector((state) => state.inventory)
  const dispatch = useAppDispatch()
  const { open, handleClose } = useToggle(kickedFromEvent)
  const { isStudent } = useUser()
  const onClose = () => {
    handleClose()
    dispatch(setKickedFromEvent(false))
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue)
  }

  const studentTabs = isUNSW()
    ? [
        {
          value: RECORDINGS_ROUTE,
          label: 'Recording',
          icon: RadioButtonCheckedIcon,
        },
        {
          value:
            (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
            (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
              ? SUMMARY_ROUTE + `/${feedback_id}`
              : SUMMARY_ROUTE,
          label: 'Summary',
          icon: DashboardCustomizeIcon,
          id: 'summary-tab-button',
        },
        {
          value:
            (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
            (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
              ? FEEDBACK_ROUTE + `/${feedback_id}`
              : FEEDBACK_ROUTE,
          label: 'Feedback',
          icon: LyricsIcon,
          id: 'feedback-tab-button',
        },
      ]
    : [
        {
          value: QUIZ_VOCAB_ROUTE,
          label: 'Vocabulary',
          icon: VocabIcon,
          iconCondition: location.pathname.startsWith(QUIZ_VOCAB_ROUTE)
            ? '#4681e0'
            : isDark
            ? '#BDBDBD'
            : '#666666',
        },
        {
          value: RECORDINGS_ROUTE,
          label: 'Workshops',
          icon: RadioButtonCheckedIcon,
        },
        {
          value:
            (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
            (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
              ? FEEDBACK_ROUTE + `/${feedback_id}`
              : FEEDBACK_ROUTE,
          label: 'Feedback',
          icon: LyricsIcon,
        },
        {
          value: TESTMYSELF_ROUTE,
          label: 'Exam Mode',
          icon: RadioButtonCheckedIcon,
        },
        {
          value:
            (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
            (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
              ? SUMMARY_ROUTE + `/${feedback_id}`
              : SUMMARY_ROUTE,
          label: 'Report',
          icon: DashboardCustomizeIcon,
          id: 'summary-tab-button',
        },
      ]
  const teacherTabs = [
    { value: NOTES_ROUTE, label: 'Speaking Tasks', icon: FeedIcon, id: 'speaking-tasks' },
    {
      value:
        (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
        (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
          ? FEEDBACK_ROUTE + `/${feedback_id}`
          : FEEDBACK_ROUTE,
      label: 'Feedback',
      icon: LyricsIcon,
    },
    {
      value:
        (location.pathname.startsWith(FEEDBACK_ROUTE) && feedback_id) ||
        (location.pathname.startsWith(SUMMARY_ROUTE) && feedback_id)
          ? SUMMARY_ROUTE + `/${feedback_id}`
          : SUMMARY_ROUTE,
      label: 'Test Result',
      icon: FactCheckIcon,
      id: 'summary-tab-button',
    },
  ]
  // TODO make it from the same array
  if (navLinks) {
    return (
      <>
        <Tabs value={value}>
          {isStudent
            ? studentTabs.map((elem, index) => {
                const Icon = elem.icon
                return (
                  <Tab
                    key={index}
                    icon={<Icon color={elem?.iconCondition ?? 'inherit'} />}
                    component={RouterLink}
                    to={elem.value}
                    value={elem.value}
                    label={<Typography variant='caption'>{elem.label}</Typography>}
                    id={elem.id}
                  />
                )
              })
            : teacherTabs.map((elem, index) => {
                const Icon = elem.icon
                return (
                  <Tab
                    key={index}
                    icon={<Icon />}
                    component={RouterLink}
                    to={elem.value}
                    label={<Typography variant='caption'>{elem.label}</Typography>}
                    value={elem.value}
                    id={elem.id}
                  />
                )
              })}
        </Tabs>
        <KickedFromEventModal open={open} onClose={onClose} />
      </>
    )
  }
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        {isStudent
          ? studentTabs.map((elem, index) => {
              const Icon = elem.icon
              return (
                <Tab
                  key={index}
                  icon={<Icon />}
                  value={elem.value}
                  label={<Typography variant='caption'>{elem.label}</Typography>}
                />
              )
            })
          : teacherTabs.map((elem, index) => {
              const Icon = elem.icon
              return (
                <Tab
                  key={index}
                  icon={<Icon />}
                  value={elem.value}
                  label={<Typography variant='caption'>{elem.label}</Typography>}
                />
              )
            })}
      </Tabs>
      <KickedFromEventModal open={open} onClose={onClose} />
    </>
  )
}

export default InventoryTabs
