import React from 'react'
import PropTypes from 'prop-types'
import { StepsContainer, StepsWrapper, StepTypography } from './Steps.styles'

const Steps = ({ currentStep, numberOfSteps }) => {
  return (
    <StepsWrapper>
      <StepsContainer>
        {[...Array(numberOfSteps).keys()].map((i) => (
          <StepTypography key={i} active={i === currentStep}>
            {i + 1}
          </StepTypography>
        ))}
      </StepsContainer>
    </StepsWrapper>
  )
}

Steps.propTypes = {
  steps: PropTypes.number,
  numberOfSteps: PropTypes.number,
}

export { Steps }
