import { lazy, Suspense } from 'react'
import { useCurrentEvent } from '@features/event/hooks'
const Geoguesser = lazy(() => import('@features/geoguesser/components/geoguesser'))
const Discussion = lazy(() => import('@features/discussion/components/discussion'))
const Hyperbeam = lazy(() => import('@features/hyperbeam/components/hyperbeam'))
const Pictionary = lazy(() => import('@features/pictionary/components/pictionary'))
const CaptionThis = lazy(() => import('@features/caption-this/components/caption-this'))
const Whiteboard = lazy(() => import('@features/whiteboard/components/whiteboard'))
const Chat = lazy(() => import('@features/chat/components/chat'))

const Activity = () => {
  const { event } = useCurrentEvent()

  const CurrentActivity = () => {
    switch (event?.event_type) {
      case 'CHAT':
        return <Chat />
      case 'GEO_GAME':
        return <Geoguesser />
      case 'DISCUSSION':
        return <Discussion />
      case 'HYPERBEAM':
        return <Hyperbeam />
      case 'PICTIONARY':
        return <Pictionary />
      case 'CAPTION_THIS':
        return <CaptionThis />
      case 'WHITEBOARD':
        return <Whiteboard />
      default:
        return <Chat />
    }
  }

  return (
    <Suspense fallback={null}>
      <CurrentActivity />
    </Suspense>
  )
}

export default Activity
