import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Container, CopyButton } from './share-link.styles'

const ShareLink = (props) => {
  const { label, link, onCopy, ...restProps } = props
  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    if (onCopy) {
      onCopy()
    }
  }
  return (
    <Container {...restProps}>
      <Typography noWrap sx={{ mr: 1 }}>
        {link}
      </Typography>
      <CopyButton
        variant='contained'
        onClick={handleCopy}
        startIcon={<ContentCopyIcon style={{ marginRight: '10px', marginLeft: '5px' }} />}
      >
        {label}
      </CopyButton>
    </Container>
  )
}

ShareLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string,
  onCopy: PropTypes.func,
  fullWidth: PropTypes.bool,
  elevation: PropTypes.number,
}

ShareLink.defaultProps = {
  elevation: 2,
  fullWidth: false,
}

export default ShareLink
