import { Typography, Box } from '@common/components'
import { styled } from '@common/utils/styles'

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#013D7B' : '#fff',
  textShadow: '-3px -3px #0061FD',
  fontFamily: 'Lexend',
}))

export const PengguinContainer = styled(Box)({
  height: '580px',
})

export const TypographyGradient = styled('span')({
  background: 'linear-gradient(99.99deg, #FF4338 26.14%, #0F82FF 61.3%, #0061FD 95.86%)',
  backgroundClip: 'text',
  textFillColor: 'transparent',
})
