import { Container, Skeleton, Grid, Stack, Box } from '@common/components'

const CommunityPlaceholder = () => {
  return (
    <Container maxWidth='lg'>
      <Grid container rowSpacing={8} sx={{ mt: 0, mb: 8, columnGap: '8%' }}>
        <Grid item xs={12} md={6}>
          <Skeleton sx={{ fontSize: '50px', width: '60%' }} />
          <Skeleton sx={{ fontSize: '50px', width: '90%' }} />
          <Skeleton sx={{ fontSize: '50px', width: '70%' }} />
          <Skeleton sx={{ fontSize: '50px', width: '45%' }} />
          <Box display='flex' justifyContent='space-between' mt={10}>
            <Skeleton variant='rounded' width={270} height={60} />
            <Skeleton variant='rounded' width={150} height={60} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          // sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Skeleton width='100%' height={500} sx={{ mt: -10 }} />
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='space-between' alignItems='flex-end ' mt={10}>
        <Box width='70%'>
          <Skeleton />
          <Skeleton width={50} />
        </Box>
        <Skeleton width={'25%'} height={50} />
      </Box>
    </Container>
  )
}

export default CommunityPlaceholder
