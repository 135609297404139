import { useAppSelector } from '@common/hooks'
import { useLocation } from 'react-router-dom'
import { selectUser } from '../auth-slice'
import { PublicUserProfile } from '@app/services'

const useUser = (event_host_id?: number) => {
  const location = useLocation()
  const user: PublicUserProfile =
    location.pathname === '/demo'
      ? {
          id: 93,
          username: 'Demo',
          email: 'demo@pengguin.com',
          bio: null,
          image: 'https://www.gravatar.com/avatar/ea618382a0c912bdec1c295b553deace',
          full_name: 'Demo',
          country: null,
          pronouns: 1,
          date_of_birth: null,
          learning: [
            {
              language: 'en',
              proficiency: 4,
            },
          ],
          address: null,
          speaking: [
            {
              language: 'en',
              proficiency: 4,
            },
          ],
          status: 'STUDENT',
          profile: null,
        }
      : useAppSelector(selectUser)

  const isTeacher = user?.status === 'TEACHER'
  const isStudent = user?.status === 'STUDENT'
  const isCompany = user?.is_company
  const isPartner = user?.profile?.partner

  const userLanguages = user
    ? [
        ...user.speaking,
        ...user.learning.filter(
          (item) =>
            user.speaking
              .map((e) => {
                return e.language
              })
              .indexOf(item.language) < 0,
        ),
      ]
    : undefined

  const isHost = user?.id === event_host_id

  return { user, isTeacher, isStudent, userLanguages, isHost, isPartner, isCompany }
}

export default useUser
