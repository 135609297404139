import { Box, Button, Modal, Typography } from '@common/components'
import { PersonRemoveOutlinedIcon } from '@common/icons'

const KickedFromEventModal = (props) => {
  const { open, onClose } = props
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: '20px' }}>
        <PersonRemoveOutlinedIcon sx={{ color: 'red' }} />
        <Typography sx={{ fontWeight: '600', marginTop: '10px' }}>Lesson Leave</Typography>
        <Typography sx={{ marginTop: '5px' }}>
          You&apos;ve been removed from the room by the host.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginTop: '20px',
          }}
        >
          <Button
            variant='outlined'
            color='neutral'
            sx={{ width: '150px', borderRadius: '10px' }}
            onClick={onClose}
          >
            Okay
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default KickedFromEventModal
