import { defer } from '@lib/router'
import { teacherApi } from '@app/services/teacher'
import { store } from '@app/store'

const groupLessonLoader = async ({ params }) => {
  const lesson = promise(params.id)
  return defer({ lesson })
}

const promise = async (lessonId: number) => {
  const result = await Promise.all([
    store.dispatch(teacherApi.endpoints.teacherGetGroupLesson.initiate({ id: lessonId })),
  ])

  return result
}

export default groupLessonLoader
