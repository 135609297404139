import { UsernameContainer } from '@common/containers'
import DisplayPublicProfile from '@features/teachers/components/display-public-profile'

const Username = () => {
  return (
    <UsernameContainer>
      <DisplayPublicProfile />
    </UsernameContainer>
  )
}

export default Username
