import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authRegisterEmail: build.mutation<AuthRegisterEmailApiResponse, AuthRegisterEmailApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/register/email`,
        method: 'POST',
        body: queryArg.userProfileRegisterEmail,
      }),
    }),
    authGuestRegisterEmail: build.mutation<
      AuthGuestRegisterEmailApiResponse,
      AuthGuestRegisterEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/guest_user/register`,
        method: 'POST',
        body: queryArg.guestRegisterUsername,
      }),
    }),
    authRegisterPhone: build.mutation<AuthRegisterPhoneApiResponse, AuthRegisterPhoneApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/register/phone_number`,
        method: 'POST',
        body: queryArg.userProfileRegisterPhone,
      }),
    }),
    authRegisterFacebook: build.query<AuthRegisterFacebookApiResponse, AuthRegisterFacebookApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/register/facebook`,
        params: { state: queryArg.state, scope: queryArg.scope, code: queryArg.code },
      }),
    }),
    authLoginFacebook: build.query<AuthLoginFacebookApiResponse, AuthLoginFacebookApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/login/facebook`,
        params: { state: queryArg.state, scope: queryArg.scope, code: queryArg.code },
      }),
    }),
    authGoogleGetAuthorization: build.mutation<
      AuthGoogleGetAuthorizationApiResponse,
      AuthGoogleGetAuthorizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/register/google/get_authorization`,
        method: 'POST',
        body: queryArg.googleGetAuthorizationRequest,
      }),
    }),
    authRegisterGoogle: build.mutation<AuthRegisterGoogleApiResponse, AuthRegisterGoogleApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/register/google`,
        method: 'POST',
        body: queryArg.googleRegisterRequest,
      }),
    }),
    authLoginGoogle: build.mutation<AuthLoginGoogleApiResponse, AuthLoginGoogleApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/login/google`,
        method: 'POST',
        body: queryArg.googleLoginRequest,
      }),
    }),
    authSyncGoogleCalendar: build.mutation<
      AuthSyncGoogleCalendarApiResponse,
      AuthSyncGoogleCalendarApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/auth/sync_google_calendar`, method: 'POST' }),
    }),
    authLinkGoogleAccount: build.mutation<
      AuthLinkGoogleAccountApiResponse,
      AuthLinkGoogleAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/link_google_account`,
        method: 'POST',
        body: queryArg.googleLoginRequest,
      }),
    }),
    authLogin: build.mutation<AuthLoginApiResponse, AuthLoginApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/login`,
        method: 'POST',
        body: queryArg.loginCred,
      }),
    }),
    authFriendlyUsername: build.mutation<
      AuthFriendlyUsernameApiResponse,
      AuthFriendlyUsernameApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/auth/friendly_username`, method: 'POST' }),
    }),
    authChangeGuestToUser: build.mutation<
      AuthChangeGuestToUserApiResponse,
      AuthChangeGuestToUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/change_guest_to_user`,
        method: 'POST',
        body: queryArg.changeGuestUserToRealUserRequest,
      }),
    }),
    authVerifyEmail: build.mutation<AuthVerifyEmailApiResponse, AuthVerifyEmailApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/verify/email`,
        method: 'POST',
        body: queryArg.verifyEmailRequest,
      }),
    }),
    authVerifyPhoneNumber: build.mutation<
      AuthVerifyPhoneNumberApiResponse,
      AuthVerifyPhoneNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/verify/phone_number`,
        method: 'POST',
        body: queryArg.verifyPhoneNumberRequest,
      }),
    }),
    authResendEmailVerification: build.mutation<
      AuthResendEmailVerificationApiResponse,
      AuthResendEmailVerificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/verify/email/resend`,
        method: 'POST',
        body: queryArg.resendEmailVerificationRequest,
      }),
    }),
    authResendPhoneNumberVerification: build.mutation<
      AuthResendPhoneNumberVerificationApiResponse,
      AuthResendPhoneNumberVerificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/verify/phone_number/resend`,
        method: 'POST',
        body: queryArg.resendPhoneNumberVerificationRequest,
      }),
    }),
    authWhoami: build.query<AuthWhoamiApiResponse, AuthWhoamiApiArg>({
      query: () => ({ url: `/api/v1/sdk/auth/me` }),
    }),
    authGetPublicProfile: build.query<AuthGetPublicProfileApiResponse, AuthGetPublicProfileApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/profile/${queryArg.username}` }),
    }),
    authGetPublicUsernameById: build.query<
      AuthGetPublicUsernameByIdApiResponse,
      AuthGetPublicUsernameByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/profile_id/${queryArg.id}` }),
    }),
    authUpdateProfile: build.mutation<AuthUpdateProfileApiResponse, AuthUpdateProfileApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/update`,
        method: 'POST',
        body: queryArg.updateProfileRequest,
      }),
    }),
    authRefreshJwtAccessToken: build.mutation<
      AuthRefreshJwtAccessTokenApiResponse,
      AuthRefreshJwtAccessTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/refresh`,
        method: 'POST',
        headers: { authorization: queryArg.authorization },
      }),
    }),
    authResetPasswordUsingEmailStep1: build.mutation<
      AuthResetPasswordUsingEmailStep1ApiResponse,
      AuthResetPasswordUsingEmailStep1ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/reset/email/step1`,
        method: 'POST',
        body: queryArg.resetPasswordUsingEmailStep1Request,
      }),
    }),
    authResetPasswordUsingEmailStep2: build.mutation<
      AuthResetPasswordUsingEmailStep2ApiResponse,
      AuthResetPasswordUsingEmailStep2ApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/reset/email/step2`,
        method: 'POST',
        body: queryArg.resetPasswordUsingEmailStep2Request,
      }),
    }),
    authRefreshRtmToken: build.mutation<AuthRefreshRtmTokenApiResponse, AuthRefreshRtmTokenApiArg>({
      query: () => ({ url: `/api/v1/sdk/auth/refresh/rtm`, method: 'POST' }),
    }),
    authOauth2Login: build.mutation<AuthOauth2LoginApiResponse, AuthOauth2LoginApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/oauth2/login`,
        method: 'POST',
        body: queryArg.bodyOauth2LoginApiV1SdkAuthOauth2LoginPost,
      }),
    }),
    authCreateRole: build.mutation<AuthCreateRoleApiResponse, AuthCreateRoleApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/role`,
        method: 'POST',
        body: queryArg.createRoleRequest,
      }),
    }),
    authGetRoles: build.query<AuthGetRolesApiResponse, AuthGetRolesApiArg>({
      query: () => ({ url: `/api/v1/sdk/auth/roles` }),
    }),
    authDeleteAccount: build.mutation<AuthDeleteAccountApiResponse, AuthDeleteAccountApiArg>({
      query: () => ({ url: `/api/v1/sdk/auth/account/delete`, method: 'DELETE' }),
    }),
    authCancelDeleteAccount: build.mutation<
      AuthCancelDeleteAccountApiResponse,
      AuthCancelDeleteAccountApiArg
    >({
      query: () => ({ url: `/api/v1/sdk/auth/account/delete/cancel`, method: 'POST' }),
    }),
    authCheckUsername: build.query<AuthCheckUsernameApiResponse, AuthCheckUsernameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/check`,
        params: { username: queryArg.username, email: queryArg.email },
      }),
    }),
    authFollowUser: build.mutation<AuthFollowUserApiResponse, AuthFollowUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/follow/${queryArg.userId}`, method: 'POST' }),
    }),
    authUnfollowUser: build.mutation<AuthUnfollowUserApiResponse, AuthUnfollowUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/unfollow/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    authGetFollowers: build.query<AuthGetFollowersApiResponse, AuthGetFollowersApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/followers/${queryArg.userId}` }),
    }),
    authGetFollowees: build.query<AuthGetFolloweesApiResponse, AuthGetFolloweesApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/followees/${queryArg.userId}` }),
    }),
    authReportUser: build.mutation<AuthReportUserApiResponse, AuthReportUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/report`,
        method: 'POST',
        body: queryArg.reportUserRequest,
      }),
    }),
    authUnreportUser: build.mutation<AuthUnreportUserApiResponse, AuthUnreportUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/sdk/auth/unreport/${queryArg.userId}`,
        method: 'POST',
      }),
    }),
    authGetReportedUsers: build.query<AuthGetReportedUsersApiResponse, AuthGetReportedUsersApiArg>({
      query: () => ({ url: `/api/v1/sdk/auth/reported` }),
    }),
    authAvoidUser: build.mutation<AuthAvoidUserApiResponse, AuthAvoidUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/avoid/${queryArg.userId}`, method: 'POST' }),
    }),
    authUnavoidUser: build.mutation<AuthUnavoidUserApiResponse, AuthUnavoidUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/unavoid/${queryArg.userId}`, method: 'POST' }),
    }),
    authGetAvoidedUsers: build.query<AuthGetAvoidedUsersApiResponse, AuthGetAvoidedUsersApiArg>({
      query: () => ({ url: `/api/v1/sdk/auth/avoided` }),
    }),
    authGetUserGoals: build.query<AuthGetUserGoalsApiResponse, AuthGetUserGoalsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/sdk/auth/user/${queryArg.userId}/goals` }),
    }),
    modulesSubmitModuleResultUnauth: build.mutation<
      ModulesSubmitModuleResultUnauthApiResponse,
      ModulesSubmitModuleResultUnauthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/modules/module/unauth/submit`,
        method: 'POST',
        body: queryArg.moduleResult,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type AuthRegisterEmailApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthRegisterEmailApiArg = {
  userProfileRegisterEmail: UserProfileRegisterEmail
}
export type AuthGuestRegisterEmailApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthGuestRegisterEmailApiArg = {
  guestRegisterUsername: GuestRegisterUsername
}
export type AuthRegisterPhoneApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthRegisterPhoneApiArg = {
  userProfileRegisterPhone: UserProfileRegisterPhone
}
export type AuthRegisterFacebookApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthRegisterFacebookApiArg = {
  state: string | null
  scope: string
  code: string
}
export type AuthLoginFacebookApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthLoginFacebookApiArg = {
  state: string | null
  scope: string
  code: string
}
export type AuthGoogleGetAuthorizationApiResponse =
  /** status 200 Successful Response */ GoogleAuthorizationUrlResponse
export type AuthGoogleGetAuthorizationApiArg = {
  googleGetAuthorizationRequest: GoogleGetAuthorizationRequest
}
export type AuthRegisterGoogleApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthRegisterGoogleApiArg = {
  googleRegisterRequest: GoogleRegisterRequest
}
export type AuthLoginGoogleApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthLoginGoogleApiArg = {
  googleLoginRequest: GoogleLoginRequest
}
export type AuthSyncGoogleCalendarApiResponse =
  /** status 200 Successful Response */ SyncedEventsResponse
export type AuthSyncGoogleCalendarApiArg = void
export type AuthLinkGoogleAccountApiResponse = /** status 201 Successful Response */ any
export type AuthLinkGoogleAccountApiArg = {
  googleLoginRequest: GoogleLoginRequest
}
export type AuthLoginApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthLoginApiArg = {
  loginCred: LoginCred
}
export type AuthFriendlyUsernameApiResponse =
  /** status 200 Successful Response */ GuestUsernameResponse
export type AuthFriendlyUsernameApiArg = void
export type AuthChangeGuestToUserApiResponse =
  /** status 200 Successful Response */ PublicUserProfile
export type AuthChangeGuestToUserApiArg = {
  changeGuestUserToRealUserRequest: ChangeGuestUserToRealUserRequest
}
export type AuthVerifyEmailApiResponse = /** status 200 Successful Response */ any
export type AuthVerifyEmailApiArg = {
  verifyEmailRequest: VerifyEmailRequest
}
export type AuthVerifyPhoneNumberApiResponse = /** status 200 Successful Response */ any
export type AuthVerifyPhoneNumberApiArg = {
  verifyPhoneNumberRequest: VerifyPhoneNumberRequest
}
export type AuthResendEmailVerificationApiResponse = /** status 200 Successful Response */ any
export type AuthResendEmailVerificationApiArg = {
  resendEmailVerificationRequest: ResendEmailVerificationRequest
}
export type AuthResendPhoneNumberVerificationApiResponse = /** status 200 Successful Response */ any
export type AuthResendPhoneNumberVerificationApiArg = {
  resendPhoneNumberVerificationRequest: ResendPhoneNumberVerificationRequest
}
export type AuthWhoamiApiResponse = /** status 200 Successful Response */ PublicUserProfile
export type AuthWhoamiApiArg = void
export type AuthGetPublicProfileApiResponse =
  /** status 200 Successful Response */ PublicUserProfile
export type AuthGetPublicProfileApiArg = {
  username: string
}
export type AuthGetPublicUsernameByIdApiResponse =
  /** status 200 Successful Response */ GetUserByIdResponse
export type AuthGetPublicUsernameByIdApiArg = {
  id: number
}
export type AuthUpdateProfileApiResponse = /** status 200 Successful Response */ any
export type AuthUpdateProfileApiArg = {
  updateProfileRequest: UpdateProfileRequest
}
export type AuthRefreshJwtAccessTokenApiResponse =
  /** status 200 Successful Response */ TokenResponse
export type AuthRefreshJwtAccessTokenApiArg = {
  authorization?: string
}
export type AuthResetPasswordUsingEmailStep1ApiResponse = /** status 200 Successful Response */ any
export type AuthResetPasswordUsingEmailStep1ApiArg = {
  resetPasswordUsingEmailStep1Request: ResetPasswordUsingEmailStep1Request
}
export type AuthResetPasswordUsingEmailStep2ApiResponse = /** status 200 Successful Response */ any
export type AuthResetPasswordUsingEmailStep2ApiArg = {
  resetPasswordUsingEmailStep2Request: ResetPasswordUsingEmailStep2Request
}
export type AuthRefreshRtmTokenApiResponse = /** status 200 Successful Response */ TokenResponse
export type AuthRefreshRtmTokenApiArg = void
export type AuthOauth2LoginApiResponse = /** status 200 Successful Response */ any
export type AuthOauth2LoginApiArg = {
  bodyOauth2LoginApiV1SdkAuthOauth2LoginPost: BodyOauth2LoginApiV1SdkAuthOauth2LoginPost
}
export type AuthCreateRoleApiResponse = /** status 200 Successful Response */ any
export type AuthCreateRoleApiArg = {
  createRoleRequest: CreateRoleRequest
}
export type AuthGetRolesApiResponse = /** status 200 Successful Response */ any
export type AuthGetRolesApiArg = void
export type AuthDeleteAccountApiResponse = unknown
export type AuthDeleteAccountApiArg = void
export type AuthCancelDeleteAccountApiResponse = /** status 200 Successful Response */ any
export type AuthCancelDeleteAccountApiArg = void
export type AuthCheckUsernameApiResponse = /** status 200 Successful Response */ any
export type AuthCheckUsernameApiArg = {
  username?: string | null
  email?: string | null
}
export type AuthFollowUserApiResponse = /** status 200 Successful Response */ any
export type AuthFollowUserApiArg = {
  userId: number
}
export type AuthUnfollowUserApiResponse = /** status 200 Successful Response */ any
export type AuthUnfollowUserApiArg = {
  userId: number
}
export type AuthGetFollowersApiResponse = /** status 200 Successful Response */ any
export type AuthGetFollowersApiArg = {
  userId: number
}
export type AuthGetFolloweesApiResponse = /** status 200 Successful Response */ any
export type AuthGetFolloweesApiArg = {
  userId: number
}
export type AuthReportUserApiResponse = /** status 200 Successful Response */ any
export type AuthReportUserApiArg = {
  reportUserRequest: ReportUserRequest
}
export type AuthUnreportUserApiResponse = /** status 200 Successful Response */ any
export type AuthUnreportUserApiArg = {
  userId: number
}
export type AuthGetReportedUsersApiResponse = /** status 200 Successful Response */ any
export type AuthGetReportedUsersApiArg = void
export type AuthAvoidUserApiResponse = /** status 200 Successful Response */ any
export type AuthAvoidUserApiArg = {
  userId: number
}
export type AuthUnavoidUserApiResponse = /** status 200 Successful Response */ any
export type AuthUnavoidUserApiArg = {
  userId: number
}
export type AuthGetAvoidedUsersApiResponse = /** status 200 Successful Response */ any
export type AuthGetAvoidedUsersApiArg = void
export type AuthGetUserGoalsApiResponse =
  /** status 200 Successful Response */ GoalsQuestionnaireDataResponse
export type AuthGetUserGoalsApiArg = {
  userId: number
}
export type ModulesSubmitModuleResultUnauthApiResponse =
  /** status 201 Successful Response */ SubmitModuleResultResponse
export type ModulesSubmitModuleResultUnauthApiArg = {
  moduleResult: ModuleResult
}
export type Token = {
  token: string
  expiration_time?: string | null
}
export type TokenResponse = {
  access_token?: Token | null
  refresh_token?: Token | null
  rtm_token?: Token | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type Pronouns = 0 | 1 | 2 | 3
export type TeacherProfileResponse = {
  bio?: string | null
  experience?: string | null
  tags?: string[] | null
  video_link?: string | null
  teaching_style?: string | null
  price_per_hour?: number | null
  partner?: boolean | null
  is_reviewer?: boolean | null
}
export type LanguageExperience = 0 | 1 | 2 | 3 | 4
export type LanguageProficiency = {
  language: string
  proficiency: LanguageExperience
}
export type AddressInfoInDb = {
  country?: string | null
  city?: string | null
  state?: string | null
  address?: string | null
  zip_code?: string | null
}
export type UserStatus = 'STUDENT' | 'TEACHER'
export type Permission =
  | 'CREATE_EVENT'
  | 'CREATE_PENGGUIN_EVENT'
  | 'SEND_DIRECT_MESSAGE'
  | 'CREATE_CUSTOM_ROLE'
  | 'SEND_EMAIL_TO_USERS'
  | 'CREATE_GEOGUESSER_GAME'
  | 'JOIN_GEOGUESSER_GAME'
  | 'MAKE_GUESS_GEOGUESSER_GAME'
  | 'MAKE_RATING'
  | 'UPLOAD_FILE'
  | 'DELETE_ACCOUNT'
  | 'MAKE_ROLE'
  | 'ASSIGN_ROLE'
  | 'VIEW_ROLES'
  | 'EDIT_ROLE'
  | 'GENERATE_AGORA_TOKEN'
  | 'REFRESH_AGORA_TOKEN'
  | 'CREATE_AMBASSADOR'
  | 'DELETE_AMBASSADOR'
  | 'UPDATE_AMBASSADOR'
  | 'TRANSLATE'
  | 'CREATE_DISCORD_ROLE'
  | 'VIEW_DISCORD_ROLE'
  | 'CREATE_GEOGUESSER_PLACES'
  | 'HYPERBEAM_LIST_SESSIONS'
  | 'HYPERBEAM_DELETE_SESSIONS'
  | 'ADMIN_VIEW_USERS'
  | 'ADMIN_LANGUAGES'
  | 'ADMIN_AGORA_TOKENS_AUDIT'
  | 'ADMIN_ROLE'
  | 'ADMIN_DIRECT_MESSAGE'
  | 'ADMIN_AMBASSADORS'
  | 'ADMIN_DISCORD_ROLES'
  | 'ADMIN_DISCORD_AUDIT_EVENT'
  | 'ADMIN_DISCUSSION_LANGUAGE'
  | 'ADMIN_DISCUSSION_LANGUAGE_AUDIT_EVENTS'
  | 'ADMIN_USER_FLAGGING'
  | 'ADMIN_GEOGAME_ROUNDS'
  | 'ADMIN_GEOGAME_STATIC_COORDINATES'
  | 'ADMIN_GEOGAME_AUDIT_EVENTS'
  | 'ADMIN_EVENT_GAME_CHAT'
  | 'ADMIN_EVENT_CHAT'
  | 'ADMIN_EVENT_PARTICIPANTS'
  | 'ADMIN_PICTIONARY_AUDIT'
  | 'ADMIN_PICTIONARY_GAMES'
  | 'ADMIN_PICTIONARY_ROUNDS'
  | 'ADMIN_PICTIONARY_GUESS'
  | 'ADMIN_PICTIONARY_WORDS'
  | 'ADMIN_TRANSLATION_AUDIT'
  | 'ADMIN_REDIS_CONSOLE'
  | 'ADMIN_DEBUGGING_CONSOLE'
  | 'CREATE_TRANSCRIPT'
  | 'ADMIN_RECORDINGS'
export type RolesInDb = {
  id?: number | null
  name?: string | null
  permissions?: Permission[] | null
}
export type UserProfileRegisterEmail = {
  id?: number | null
  username?: string | null
  email: string
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
  roles?: RolesInDb[] | null
  password?: string | null
  registration_code?: string | null
  promotion_code?: string | null
}
export type GuestRegisterUsername = {
  username: string
  status: UserStatus
}
export type UserProfileRegisterPhone = {
  id?: number | null
  username?: string | null
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
  roles?: RolesInDb[] | null
  phone_number: string
  password: string
  registration_code?: string | null
}
export type GoogleAuthorizationUrlResponse = {
  url: string
}
export type GoogleGetAuthorizationRequest = {
  redirect_url: string
  ext?: object | null
}
export type GoogleRegisterRequest = {
  code: string
  redirect_url: string
}
export type GoogleLoginRequest = {
  code: string
  redirect_url: string
  ext?: object | null
}
export type SyncedEventsResponse = {
  event_count: number
}
export type LoginCred = {
  password: string
  email?: string | null
  phone_number?: string | null
  username?: string | null
}
export type GuestUsernameResponse = {
  username: string
}
export type PublicUserProfile = {
  id?: number | null
  username?: string | null
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
  roles?: RolesInDb[] | null
}
export type ChangeGuestUserToRealUserRequest = {
  email: string
}
export type VerifyEmailRequest = {
  email: string
  code: string
}
export type VerifyPhoneNumberRequest = {
  phone_number: string
  code: string
}
export type ResendEmailVerificationRequest = {
  email: string
}
export type ResendPhoneNumberVerificationRequest = {
  phone_number: string
}
export type GetUserByIdResponse = {
  id: number
  username: string
}
export type UpdateProfileRequest = {
  bio?: string | null
  image?: string | null
  full_name?: string | null
  password?: string | null
  display_name?: string | null
}
export type ResetPasswordUsingEmailStep1Request = {
  email: string
}
export type ResetPasswordUsingEmailStep2Request = {
  code: string
  email: string
  password: string
}
export type BodyOauth2LoginApiV1SdkAuthOauth2LoginPost = {
  grant_type?: string | null
  username: string
  password: string
  scope?: string
  client_id?: string | null
  client_secret?: string | null
}
export type CreateRoleRequest = {
  name: string
  permissions: Permission[]
}
export type ReportUserRequest = {
  user_id: number
  reason: string
}
export type GoalsQuestionnaireDataResponse = {
  goal: string[]
  occupation: {
    [key: string]: string
  }
  interests: string[]
  about_me: string
}
export type SubmitModuleResultResponse = {
  id: number
}
export type ModuleResult = {
  id: string
  data: object
}
export const {
  useAuthRegisterEmailMutation,
  useAuthGuestRegisterEmailMutation,
  useAuthRegisterPhoneMutation,
  useAuthRegisterFacebookQuery,
  useLazyAuthRegisterFacebookQuery,
  useAuthLoginFacebookQuery,
  useLazyAuthLoginFacebookQuery,
  useAuthGoogleGetAuthorizationMutation,
  useAuthRegisterGoogleMutation,
  useAuthLoginGoogleMutation,
  useAuthSyncGoogleCalendarMutation,
  useAuthLinkGoogleAccountMutation,
  useAuthLoginMutation,
  useAuthFriendlyUsernameMutation,
  useAuthChangeGuestToUserMutation,
  useAuthVerifyEmailMutation,
  useAuthVerifyPhoneNumberMutation,
  useAuthResendEmailVerificationMutation,
  useAuthResendPhoneNumberVerificationMutation,
  useAuthWhoamiQuery,
  useLazyAuthWhoamiQuery,
  useAuthGetPublicProfileQuery,
  useLazyAuthGetPublicProfileQuery,
  useAuthGetPublicUsernameByIdQuery,
  useLazyAuthGetPublicUsernameByIdQuery,
  useAuthUpdateProfileMutation,
  useAuthRefreshJwtAccessTokenMutation,
  useAuthResetPasswordUsingEmailStep1Mutation,
  useAuthResetPasswordUsingEmailStep2Mutation,
  useAuthRefreshRtmTokenMutation,
  useAuthOauth2LoginMutation,
  useAuthCreateRoleMutation,
  useAuthGetRolesQuery,
  useLazyAuthGetRolesQuery,
  useAuthDeleteAccountMutation,
  useAuthCancelDeleteAccountMutation,
  useAuthCheckUsernameQuery,
  useLazyAuthCheckUsernameQuery,
  useAuthFollowUserMutation,
  useAuthUnfollowUserMutation,
  useAuthGetFollowersQuery,
  useLazyAuthGetFollowersQuery,
  useAuthGetFolloweesQuery,
  useLazyAuthGetFolloweesQuery,
  useAuthReportUserMutation,
  useAuthUnreportUserMutation,
  useAuthGetReportedUsersQuery,
  useLazyAuthGetReportedUsersQuery,
  useAuthAvoidUserMutation,
  useAuthUnavoidUserMutation,
  useAuthGetAvoidedUsersQuery,
  useLazyAuthGetAvoidedUsersQuery,
  useAuthGetUserGoalsQuery,
  useLazyAuthGetUserGoalsQuery,
  useModulesSubmitModuleResultUnauthMutation,
} = injectedRtkApi
