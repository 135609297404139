import { listen } from '@features/agora/hooks/misc/listen'
import AgoraRTC, {
  ClientConfig,
  createCameraVideoTrack,
  createClient,
  createMicrophoneAudioTrack,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
} from '@features/agora/lib/agora-rtc'
import React from 'react'
import { NetworkQualityEx } from './types/types'

const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}

const useClient = createClient(config)
const useClient2 = createClient({
  ...config,
  role: 'host',
}) // coz need new client for screen share

const useMicrophoneAudioTrack = createMicrophoneAudioTrack()
const useCameraVideoTrack = createCameraVideoTrack({
  encoderConfig: {
    width: { ideal: 426, min: 426, max: 426 },
    height: { ideal: 240, min: 240, max: 240 },
    frameRate: 15,
    bitrateMin: 600,
    bitrateMax: 1000,
  },
})

const initQuality = (): NetworkQualityEx => ({
  uplinkNetworkQuality: 0,
  downlinkNetworkQuality: 0,
  delay: 0,
})

interface useAgoraChatProps {
  appId: string
  channel: string
  token: string
  userId: string
  constraints?: {
    video?: boolean
    audio?: boolean
    microphoneId?: string
    cameraId?: string
  }
  isFetching: boolean
  screenShareToken: string
  refetchEvent: () => Promise<void>
}

const useAgoraChat = (props: useAgoraChatProps) => {
  const { appId, channel, token, userId, constraints, isFetching, screenShareToken, refetchEvent } =
    props
  const [trackState, setTrackState] = React.useState({
    video: Boolean(constraints?.video),
    audio: Boolean(constraints?.audio),
    screen: false,
  })
  const [publishedTracks, setPublishedTracks] = React.useState({
    video: false,
    audio: false,
  })

  const screenTrack = React.useRef<ILocalVideoTrack>()
  const [users, setUsers] = React.useState<IAgoraRTCRemoteUser[]>([])
  const [volumes, setVolumes] = React.useState([])
  const [joined, setJoined] = React.useState<string | null>(null)
  const [isCnxCrap, setIsCnxCrap] = React.useState(false)

  // using the hook to get access to the client object
  const client = useClient()
  const screenClient = useClient2()
  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready: readyA, track: localAudioTrack } = useMicrophoneAudioTrack()
  const { ready: readyV, track: localVideoTrack } = useCameraVideoTrack()

  const changeAudioMute = async (newState: boolean) => {
    if (localAudioTrack) {
      await localAudioTrack.setEnabled(newState)
      setTrackState((ps) => {
        return { ...ps, audio: newState }
      })
    }
  }
  const toggleAudioMute = async () => {
    if (localAudioTrack) {
      const newState = !trackState.audio
      await localAudioTrack.setEnabled(newState)
      setTrackState((ps) => {
        return { ...ps, audio: newState }
      })
    }
  }

  const changeVideoMute = async (newState: boolean) => {
    if (localVideoTrack) {
      await localVideoTrack?.setEnabled(newState)
      setTrackState((ps) => {
        return { ...ps, video: newState }
      })
    }
  }
  const toggleVideoMute = async () => {
    if (localVideoTrack) {
      const newState = !trackState.video
      await localVideoTrack?.setEnabled(newState)
      setTrackState((ps) => {
        return { ...ps, video: newState }
      })
    }
  }

  const leave = async () => {
    await client.unpublish()
    await client.leave()

    if (screenClient.channelName && screenClient.connectionState === 'CONNECTED') {
      await screenClient.unpublish()
      await screenClient.leave()
      setTrackState((ps) => {
        return { ...ps, screen: false }
      })
    }
    setJoined(null)
    // reset publishedtracks
    setPublishedTracks({
      video: false,
      audio: false,
    })
  }

  React.useEffect(() => {
    const setCorrectDevice = async () => {
      if (readyA && props.constraints?.microphoneId) {
        await localAudioTrack?.setDevice(props.constraints.microphoneId)
      }
    }
    setCorrectDevice()
  }, [props.constraints?.microphoneId, readyA])

  React.useEffect(() => {
    const setCorrectDevice = async () => {
      if (readyV && props.constraints?.cameraId) {
        await localVideoTrack?.setDevice(props.constraints.cameraId)
      }
    }
    setCorrectDevice()
  }, [props.constraints?.cameraId, readyV])

  React.useEffect(() => {
    const enableLocalVideoTrack = async () => {
      if (readyV && !trackState.video && localVideoTrack) {
        await localVideoTrack.setEnabled(false)
      }
    }
    enableLocalVideoTrack()
  }, [readyV, localVideoTrack, trackState.video])

  const toggleScreenshare = async () => {
    if (!trackState.screen) {
      screenTrack.current = await AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: '4p',
        },
        'disable',
      )
      screenTrack.current.on('track-ended', async () => {
        console.log('TRACK ENDED')
        if (screenTrack.current) {
          screenTrack.current.close()
        }
        await screenClient.leave()
        setTrackState((ps) => {
          return { ...ps, screen: false }
        })
      })
      await screenClient.join(appId, channel, screenShareToken, `${userId}-screen`)
      if (screenTrack.current.enabled) {
        await screenClient.publish([screenTrack.current])
      }
      setTrackState((ps) => {
        return { ...ps, screen: true }
      })
    } else {
      if (screenTrack.current) {
        screenTrack.current.close()
      }
      await screenClient.leave()
      setTrackState((ps) => {
        return { ...ps, screen: false }
      })
    }
  }
  React.useEffect(() => {
    const init = async () => {
      await client.enableDualStream()
      AgoraRTC.setParameter('AUDIO_VOLUME_INDICATION_INTERVAL', 200)
      client.enableAudioVolumeIndicator()

      listen(client, 'user-joined', async (user) => {
        if (user && refetchEvent) {
          console.log('refetching event')
          await refetchEvent()
        }
        console.log('user-joined', user)
        console.log('user-joined', client.remoteUsers)
        setUsers((remoteUsers) => Array.from(client.remoteUsers))
      })

      listen(client, 'user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType)
        if (user && user?.hasAudio) user?.audioTrack.play()
        setUsers((remoteUsers) => Array.from(client.remoteUsers))
      })

      listen(client, 'user-unpublished', (user, type) => {
        setUsers((remoteUsers) => Array.from(client.remoteUsers))
      })

      listen(client, 'user-left', (user) => {
        setUsers((remoteUsers) => Array.from(client.remoteUsers))
      })

      listen(client, 'volume-indicator', (volumes) => setVolumes(volumes))
    }
    init()
  }, [])

  // React.useEffect(() => {
  //   if (joined && token && channel) {
  //     setUsers(Array.from(client.remoteUsers))
  //   }
  // }, [client.remoteUsers, joined, token, channel])

  React.useEffect(() => {
    const init = async () => {
      if (client.connectionState === 'CONNECTING') {
        return
      }

      if (joined) {
        await leave()
      }
      await client.join(appId, channel, token, userId)
      client.enableAudioVolumeIndicator()
      setJoined(channel)
      console.log('JOINED', channel)
    }
    if (channel && token && userId && !isFetching) {
      init()
    }
  }, [channel, token, userId, isFetching])

  React.useEffect(() => {
    const publishAudio = async () => {
      if (
        localAudioTrack?.enabled &&
        joined &&
        trackState.audio &&
        !publishedTracks.audio &&
        channel &&
        token
      ) {
        await client.publish([localAudioTrack])
        setPublishedTracks({ ...publishedTracks, audio: true })
      }
    }
    publishAudio()
  }, [joined, localAudioTrack?.enabled, trackState.audio, publishedTracks.audio, channel, token])

  React.useEffect(() => {
    const publishVideo = async () => {
      if (
        localVideoTrack?.enabled &&
        joined &&
        trackState.video &&
        !publishedTracks.video &&
        channel &&
        token
      ) {
        await client.publish([localVideoTrack])
        setPublishedTracks({ ...publishedTracks, video: true })
      }
    }
    publishVideo()
  }, [joined, localVideoTrack?.enabled, trackState.video, publishedTracks.audio, channel, token])

  React.useEffect(() => {
    const cleanup = () => {
      leave()
    }
    return cleanup
  }, [])

  const [networkQuality, setNetworkQuality] = React.useState<NetworkQualityEx>(initQuality)
  React.useEffect(() => {
    if (client) {
      return listen(client, 'network-quality', (q) =>
        setNetworkQuality({
          uplinkNetworkQuality: q.uplinkNetworkQuality,
          downlinkNetworkQuality: q.downlinkNetworkQuality,
          delay: client.getRTCStats().RTT ?? 0,
        }),
      )
    } else {
      setNetworkQuality(initQuality())
    }
  }, [client])

  React.useEffect(() => {
    if (networkQuality.downlinkNetworkQuality >= 4 || networkQuality.uplinkNetworkQuality >= 4) {
      setIsCnxCrap(true)
    } else {
      setIsCnxCrap(false)
    }
  }, [networkQuality.downlinkNetworkQuality, networkQuality.uplinkNetworkQuality])

  return {
    joined,
    localTracks: [
      localAudioTrack?.enabled ? localAudioTrack : undefined,
      localVideoTrack?.enabled ? localVideoTrack : undefined,
    ],
    localTracksReady: readyA || readyV,
    users: Array.from(client.remoteUsers),
    volumes,
    trackState,
    changeAudioMute,
    changeVideoMute,
    toggleAudioMute,
    toggleVideoMute,
    toggleScreenshare,
    leave,
    isCnxCrap,
  }
}

export default useAgoraChat
