import * as React from 'react'
import { Box, Tab, Tabs, Typography, Stack } from '@common/components'
import AvailabeTimes from '../availabe-times'
import { useTranslation } from '@common/hooks'

const PublicUserTabs = ({ data }) => {
  const { t } = useTranslation('public-user-info')

  const [value, setValue] = React.useState(0)
  const aboutme = React.useRef(null)
  const teacher = React.useRef(null)
  const lessons = React.useRef(null)

  const showTeacherInfo = () => {
    teacher.current.scrollIntoView({ behavior: 'smooth' })
  }
  const showAboutMe = () => {
    aboutme.current.scrollIntoView({ behavior: 'smooth' })
  }
  const showLessons = () => {
    lessons.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Stack sx={{}}>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          onClick={showAboutMe}
          label={
            <Typography variant='caption'>
              {' '}
              {t('public-user-info.body.bio', ' About me')}
            </Typography>
          }
        />
        <Tab
          onClick={showTeacherInfo}
          label={
            <Typography variant='caption'>
              {' '}
              {t('public-user-info.body.me-as-teacher', ' Me as a teacher')}
            </Typography>
          }
        />
        <Tab
          onClick={showLessons}
          label={
            <Typography variant='caption'>
              {t('public-user-info.body.teaching-style', ' My lessons & teaching style')}
            </Typography>
          }
        />
      </Tabs>
      <Box
        sx={{
          height: '300px',
          overflowY: 'scroll',
          px: 3,
        }}
      >
        <Typography ref={aboutme} sx={{ mb: 3 }}>
          {data.bio}
        </Typography>
        <Typography ref={teacher} sx={{ mb: 3 }}>
          aliquam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore provident quae
          fugiat error odio, sed iusto. A reprehenderit nobis quae sint expedita error, natus
          deserunt libero perferendis repellat, beatae unde.lorem. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Iste commodi illo quo autem delectus dolorem vitae
          reiciendis minus distinctio, obcaecati pariatur repellendus inventore nesciunt facere quis
          consectetur recusandae ratione! Eum. Lorem ipsum dolor, sit amet consectetur adipisicing
          elit. Magnam obcaecati et
          <br></br>
        </Typography>
        <Typography ref={lessons} sx={{ mb: 3 }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero laudantium ea quos omnis,
          quisquam consectetur a veritatis deleniti numquam asperiores fugit accusamus ullam aperiam
          cupiditate esse error nostrum non iusto?
          <br></br>
        </Typography>

        <AvailabeTimes />
      </Box>
    </Stack>
  )
}
export default PublicUserTabs
