import React from 'react'
import { GlobalStyles } from '@common/utils/styles'
import { Crisp } from 'crisp-sdk-web'
import { CRISP_WEBSITE_ID } from '@config'
import { useLocation } from 'react-router'

const CrispChat = () => {
  const location = useLocation()
  const isEventsPage = location.pathname.slice(0, 10) === '/classroom'

  React.useEffect(() => {
    // init crisp chat
    Crisp.configure(`${CRISP_WEBSITE_ID}`)
  }, [])

  return (
    <>
      <div></div>
      <GlobalStyles
        styles={{
          '.crisp-client': {
            display: isEventsPage && 'none',
          },
        }}
      />
    </>
  )
}

export default CrispChat
