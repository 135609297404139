export const languages = [
  {
    label: 'English',
    flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/gb.svg',
    code: 'en',
  },
  {
    label: 'French',
    flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/fr.svg',
    code: 'fr',
  },
  // {
  //   label: 'German',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/de.svg',
  //   code: 'de',
  // },
  // {
  //   label: 'Italian',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/it.svg',
  //   code: 'it',
  // },
  {
    label: 'Spanish',
    flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/es.svg',
    code: 'es',
  },
  // {
  //   label: 'Portuguese',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/br.svg',
  //   code: 'pt',
  // },
  // {
  //   label: 'Russian',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/ru.svg',
  //   code: 'ru',
  // },
  // {
  //   label: 'Arabic',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/tn.svg',
  //   code: 'ar',
  // },
  // {
  //   label: 'Japanese',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/jp.svg',
  //   code: 'ja',
  // },
  // {
  //   label: 'Chinese',
  //   flag_img: 'https://storage.googleapis.com/static-pengguin/flags/4x3/cn.svg',
  //   code: 'zh',
  // },
]
