import './config/sentry'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import './config/i18n'
import './app/services'
import './styles/globals.css'
import './config/fonts'
import { register } from '@lib/serviceWorkerRegistration'

register()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
